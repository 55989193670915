.MapBackground {
    position: absolute;
    touch-action: none;
}

/* NOTE: If any of these top/left/scale are changed, all existing scenarios become incorrect */
/* TODO: Should probably use pan preset instead, for setting initial view */
.MapBackground > img {
    position: absolute;
    opacity: 0.5;
    transform-origin: 0 0;
    transform: scale(100%);
    touch-action: none;
    -webkit-touch-callout: none;
}

.MapBackground > img.caucasus {
    left: -1000px;
    top: -800px;
}

.MapBackground > img.syria {
    left: -1100px;
    top: -1200px;
}

.MapBackground > img.persian-gulf {
    left: -600px;
    top: -2200px;
}