:root {
  /* TODO: Add some generic css variables for panel colors, buttons, border radius etc */
  --viewport-margin: 12px;
  --panel-border-radius: 6px;
  --doc-height: 100%;
}
@media screen and (min-width: 600px) {
  :root {
    --viewport-margin: 20px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  height: var(--doc-height);
}

body {
  overflow: hidden;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

input {
  background-color: #555;
  color: white;
  border: 1px solid #333;
  border-radius: 6px;
  padding: 6px 6px;
  margin: 0;
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  cursor: inherit;
  line-height: inherit;
  outline: none;  
}

select {
  /* appearance: none; */
  background-color: #555;
  color: white;
  border: 1px solid #333;
  border-radius: 6px;
  padding: 6px 4px;
  margin: 0;
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}