.Measurement {
    transform-origin: 0 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 11;
}

.Measurement p {
    /* flex-wrap: nowrap; */
    text-align: center;
    white-space: nowrap;
    font-size: 13px;
    font-weight: bold;
    margin: 0 6px;
    padding: 0;
    color: #385279;
    text-shadow: 0 0 1px white;
    flex-shrink: 1;
}

.Measurement.is-selected p {
    outline: 1px dashed #666;
    outline-offset: 3px;
}

.Measurement p.empty {
    margin: 0;
}

.Measurement .left-arrow, .Measurement .right-arrow {
    flex-shrink: 0;
    font-size: 14px;
    color: #385279;
}

.Measurement .left-arrow {
    text-align: left;
    margin-right: -2px;
}

.Measurement .right-arrow {
    text-align: right;
    margin-left: -2px;
}

.Measurement .line {
    flex-basis: 50%;
    flex-shrink: 1;
    background-color: #385279;
    height: 2px;
    margin: 7px 0;
}