.ObjectEditor {
    position: absolute;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: var(--viewport-margin);
    right: var(--viewport-margin);
    width: 200px;
    padding: 8px;
    background-color: #444;
    color: white;
    border: 1px solid #333;
    border-radius: var(--panel-border-radius);
    overflow: hidden;
}

.ObjectEditor h2 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0;
}

.ObjectEditor label {
    text-align: left;
    display: block;
    font-size: 11px;
    margin-top: 4px;
}

.ObjectEditor input {
    display: block;
    font-size: 14px;
    margin-top: 4px;
}

.ObjectEditor .emoji-buttons {
    display: flex;
    padding: 2px 0;
    gap: 3px;
    /* padding: 4px 0; */
}

.ObjectEditor .clickable {
    padding: 1px 3px;
    font-size: 14px;
    background-color: #555;
    border-radius: 3px;
}
.ObjectEditor .clickable:hover {
    background-color: #5a5a5a;
}
