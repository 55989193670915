.Mainbar {
    position: absolute;
    height: 50px;
    max-width: 500px;
    padding: 0 10px 0 20px;
    z-index: 101;
    display: flex;
    gap: 8px;
    align-content: flex-start;
    align-items: center;
    left: calc(var(--viewport-margin) + 101px + var(--viewport-margin));
    top: var(--viewport-margin);
    right: var(--viewport-margin);
    border: 1px solid #333;
    background-color: #444;
    border-radius: var(--panel-border-radius);
    overflow: hidden;
    color: white;
}

.Mainbar .name-container {
    flex-grow: 1;
}

.Mainbar h1 {
    cursor: pointer;
    font-size: 14px;
}
@media screen and (min-width: 600px) {
    .Mainbar h1 {
        cursor: pointer;
        font-size: 18px;
    }
}



/* TODO: Move this duplicated styling to something common */
.Mainbar .clickable {
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    color: white;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #666;
    border: .5px solid #444;
    border-radius: 6px;
    user-select: none;
    font-size: 24px;
}

.Mainbar .clickable .svg-icon {
    display: block;
    height: 30px;
    width: 30px;
    padding: 7px 0;
    fill: white;
    flex-shrink: 0;
    filter: drop-shadow( 0px 0px 1px black);
}