.Controlbar {
    position: absolute;
    z-index: 101;
    display: flex;
    align-content: flex-start;
    align-items: center;
    left: var(--viewport-margin);
    bottom: var(--viewport-margin);
    right: var(--viewport-margin);
    max-width: 620px;
    height: 42px;
    border: 1px solid #333;
    border-radius: var(--panel-border-radius);
    overflow: hidden;
    background-color: white;
}

.Controlbar .buttons {
    display: flex;
    align-content: flex-start;
    background-color: #666;
}

.Controlbar .buttons .clickable {
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    color: white;
    cursor: pointer;
    width: 41px;
    height: 41px;
    background-color: #666;
    border: .5px solid #444;
    user-select: none;
    font-size: 24px;
}
.Controlbar .buttons .clickable.selected {
    background-color: #555;
    box-shadow: inset 0 0 4px #333;
}

.Controlbar .buttons .clickable .svg-icon {
    display: block;
    height: 30px;
    width: 30px;
    padding: 7px 0;
    fill: white;
    flex-shrink: 0;
    filter: drop-shadow( 0px 0px 1px black);
}

.Controlbar .buttons .clickable.playback-speed {
    font-size: 12px;
}

.Controlbar .toggle-paths > .when_paused {
    opacity: 0.7;
}

.Controlbar .toggle-paths > .always {
    opacity: 1.0;
}

.Controlbar .toggle-paths > .never {
    opacity: 0.2;
}