.Timebar {
    position: relative;
    touch-action: none;
    margin: 0 6px;
    background: #bbb;
    height: 30px;
    max-width: 400px;
    flex: 1;
}

.Timebar .handle {
    position: absolute;
    pointer-events: none;
    top: -1px;
    left: 0;
    background: #999;
    opacity: 0.9;
    height: 32px;
    width: 4px;
    /* box-shadow: 0 0 1px black; */
    /* border: 1px solid #888; */
    border-radius: 3px;
}
