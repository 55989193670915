.Toolbar {
    position: absolute;
    z-index: 100;
    top: var(--viewport-margin);
    left: var(--viewport-margin);
    width: 103px;
    border: 1px solid #333;
    background-color: #444;
    border-radius: var(--panel-border-radius);
    max-height: 90vh;
    overflow: hidden;
}

.Toolbar .category {
    position: relative;
    display: flex;
    margin-top: 1px;
    flex-direction: column;
    row-gap: 1px;
    align-content: flex-start;
    /* background-color: #666; */
    width: 100%;
}

.Toolbar .category:first-child {
    margin-top: 0;
}

.Toolbar .category .shadow {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 12px;
    background: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.3));
    pointer-events: none;
    transition: opacity 200ms linear;
    opacity: 0.01;
}

.Toolbar .category .shadow.visible {
    opacity: 1.0;
}

.Toolbar .category > button {
    appearance: none;
    cursor: pointer;
    border: none;
    font-size: 11px;
    height: 20px;
    font-weight: bold;
    /* margin: 4px 0; */
    color: white;
    background-color: #666;
    text-shadow: 0 0 2px black;
}

.Toolbar .buttons {
    display: flex;
    gap: 1px;
    align-content: flex-start;
    flex-wrap: wrap;
    /* background-color: #666; */
    width: 100%;
    will-change: max-height;
    transition: max-height 200ms linear;
    max-height: 400px;
    overflow: hidden;
}

.Toolbar .buttons > button {
    display: flex;
    border: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    appearance: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: #666;
    user-select: none;
}
.Toolbar .buttons > button.selected {
    background-color: #555;
    box-shadow: inset 0 0 4px #333;
    cursor: default;
}

.Toolbar .buttons > button > img {
    display: block;
    width: 32px;
    height: 32px;
    padding: 2px;
    flex-shrink: 0;
}

.Toolbar .buttons > button > .big-text {
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    padding: 2px 0;
    flex-shrink: 0;
    text-shadow: 0 0 2px black;
}

.Toolbar .buttons > button > .svg-icon {
    display: block;
    height: 32px;
    width: 32px;
    padding: 7px;
    fill: white;
    flex-shrink: 0;
    filter: drop-shadow( 0px 0px 1px black);
}

.Toolbar .buttons > button > p {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 8px;
    text-shadow: 0 0 2px black;
    text-transform: capitalize;
}