.Workspace {
    position: absolute;
    touch-action: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eee;
    -webkit-touch-callout: none;
}

.Workspace .panner {
    transform-origin: 0 0;
    touch-action: none;
    -webkit-touch-callout: none;
}