.Label {
    transform-origin: 0 0%;
    /* TODO: Is flex needed here? */
    display: flex;
    z-index: 10;
    position: absolute;
    min-width: 80px;
    max-width: 120px;
}


.Label p {
    font-size: 13px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    color: #385279;
    text-shadow: 0 0 1px white;
}

.Label.is-selected p {
    outline: 1px dashed #666;
    outline-offset: 3px;
}