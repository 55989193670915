.Unit {
    position: absolute;
    user-select: none;
    /* transform-origin: -50% -50%; */
}

.Unit.is-static {
    z-index: 1;
}

.Unit.is-ground-mover {
    z-index: 2;
}

.Unit.is-weapon {
    z-index: 3;
}

.Unit.is-helicopter {
    z-index: 4;
}

.Unit.is-airplane {
    z-index: 5;
}

.Unit .path {
    position: absolute;
    pointer-events: none;
}

.Unit .path svg {
    fill: none;
    stroke: #999;
    stroke-width: 5;
    stroke-opacity: 0.12;
    pointer-events: none;
}

.Unit .primary-container {
    position: absolute;
    width: 32px;
    height: 32px;
    user-select: none;
}

.Unit .primary-container.is-selected {
    outline: 1px dashed #666;
    outline-offset: 3px;
}

.Unit .graphics-container {
    width: 100%;
    height: 100%;
    user-select: none;
    /* transform-origin: -50% -50%; */
}

.Unit .primary-container.coalition-red .graphics-container {
    filter: drop-shadow(0 0 4px rgba(255, 0, 0, 0.4));
}

.Unit .primary-container.coalition-blue .graphics-container {
    filter: drop-shadow(0 0 4px rgba(0, 0, 255, 0.4));
}

.Unit .unit-image {
    height: 100%;
    width: 100%;
    transform: rotate(45deg);
    user-select: none;
}

.Unit .inactive .unit-image {
    opacity: 0.5;
}
.Unit .ghost .unit-image {
    opacity: 0.2;
}

.Unit p {
    position: absolute;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    min-width: 80px;
    font-size: 11px;
    font-weight: bold;
    margin: 0;
    margin-top: 3px;
    color: #555;
    text-shadow: 0 0 1px white;
}

.Unit p.large {
    font-size: 14px;
}